import * as client_hooks from '../../../src/hooks.client.js';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21')
];

export const server_loads = [0];

export const dictionary = {
		"/": [~2],
		"/accept": [~3],
		"/admin/accept-invite": [~4],
		"/admin/add-store": [~5],
		"/contact": [~6],
		"/dashboard": [~7],
		"/forgot": [~8],
		"/login": [~9],
		"/privacy-policy": [10],
		"/register": [~11],
		"/reset": [~12],
		"/settings": [~13],
		"/store-owner/add-employee": [14],
		"/store-owner/integrations": [~15],
		"/stores": [~16],
		"/stores/[id]": [~17],
		"/subscribe": [~18],
		"/transactions": [~19],
		"/transactions/[id]": [~20],
		"/verify": [21]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';